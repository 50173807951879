@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNovaT-Thin.eot');
	src: url('../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
	url('../fonts/ProximaNovaT-Thin.woff') format('woff'),
	url('../fonts/ProximaNovaT-Thin.ttf') format('truetype'),
	url('../fonts/ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Light.eot');
	src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ProximaNova-Light.woff2') format('woff2'),
	url('../fonts/ProximaNova-Light.woff') format('woff'),
	url('../fonts/ProximaNova-Light.ttf') format('truetype'),
	url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Regular.eot');
	src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
	url('../fonts/ProximaNova-Regular.woff') format('woff'),
	url('../fonts/ProximaNova-Regular.ttf') format('truetype'),
	url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Bold.eot');
	src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
	url('../fonts/ProximaNova-Bold.woff') format('woff'),
	url('../fonts/ProximaNova-Bold.ttf') format('truetype'),
	url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-Semibold.eot');
	src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
	url('../fonts/ProximaNova-Semibold.woff') format('woff'),
	url('../fonts/ProximaNova-Semibold.ttf') format('truetype'),
	url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Solomon Sans';
	src: url('../fonts/Solomon-Sans-Black.eot');
	src: url('../fonts/Solomon-Sans-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Solomon-Sans-Black.woff2') format('woff2'),
	url('../fonts/Solomon-Sans-Black.woff') format('woff'),
	url('../fonts/Solomon-Sans-Black.ttf') format('truetype'),
	url('../fonts/Solomon-Sans-Black.svg#Solomon-Sans-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Solomon Sans';
	src: url('../fonts/Solomon-Sans-SemiBold.eot');
	src: url('../fonts/Solomon-Sans-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Solomon-Sans-SemiBold.woff2') format('woff2'),
	url('../fonts/Solomon-Sans-SemiBold.woff') format('woff'),
	url('../fonts/Solomon-Sans-SemiBold.ttf') format('truetype'),
	url('../fonts/Solomon-Sans-SemiBold.svg#Solomon-Sans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Solomon Sans';
	src: url('../fonts/Solomon-Sans-Normal.eot');
	src: url('../fonts/Solomon-Sans-Normal.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Solomon-Sans-Normal.woff2') format('woff2'),
	url('../fonts/Solomon-Sans-Normal.woff') format('woff'),
	url('../fonts/Solomon-Sans-Normal.ttf') format('truetype'),
	url('../fonts/Solomon-Sans-Normal.svg#Solomon-Sans-Normal') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon/fonts/icomoon.eot?qebrgk');
	src:  url('../fonts/icomoon/fonts/icomoon.eot?qebrgk#iefix') format('embedded-opentype'),
	url('../fonts/icomoon/fonts/icomoon.ttf?qebrgk') format('truetype'),
	url('../fonts/icomoon/fonts/icomoon.woff?qebrgk') format('woff'),
	url('../fonts/icomoon/fonts/icomoon.svg?qebrgk#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\e907";
}
.icon-ic2:before {
  content: "\e900";
}
.icon-ic3:before {
  content: "\e901";
}
.icon-od:before {
  content: "\e902";
  color: #d1d3d2;
}
.icon-fb:before {
  content: "\e903";
  color: #d1d3d2;
}
.icon-vk:before {
  content: "\e904";
  color: #d1d3d2;
}
.icon-ic1:before {
  content: "\e905";
}
.icon-inst:before {
  content: "\e906";
  color: #d1d3d2;
}
