
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
.header{
	width:100%;
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
    padding-top: 22px;
    border-top: 3px solid #fff;
	z-index: 10;
	&__wrap {
		display: flex;
		justify-content: space-between;
		margin-left: -10px;
		margin-right: -10px;
	}
	&__item {
		padding: 0 10px;
      &-btn {
        position: relative;
        margin-right: 45px;
        display: inline-block;
      }
      &_btn {
        .btn-wrap_row {
          margin: -10px -10px -10px -20px;
        }
      }
      &_row {
        display: flex;
        align-items: center;
      }
	}
}

.header__item-btn-content-mob {
  display: none;
}

.logo {
	padding: 0;
	margin-top: -25px;
	&__img {
		width: 159px;
		height: 33px;
	}
	&__link {
		display: block;
		background: #fff;
        padding: 24px 38px 31px 30px;
		border-radius: 0 0 20px 20px;
        color: #40b711;
        font-size: 33px;
	}
}

.btn {
	display: inline-block;
	font-weight: 600;
	color: #40b711;
	border-radius: 30px;
	border: 2px solid #40b711;
	min-width: 265px;
	padding: 23px 15px;
	text-align: center;
	&-wrap {
      z-index: 1;
      position: relative;
		&_row {
			display: flex;
			align-items: center;
			margin: -10px;
		}
	}
	&__item {
		padding: 10px;
	}
	&:hover {
		background: #40b711;
		color: #fff;
		box-shadow: 0 12px 30px rgba(1,22,41,.1);
	}
	&_width {
		min-width: 240px;
	}
	&_bg {
		border: none;
		color: #fff;
		background: #40b711;
		box-shadow: 0 12px 30px rgba(1,22,41,.1);
      &:hover {
        opacity: .7;
      }
	}
	&_submit {
		min-width: 233px;
		font-weight: 600;
		transition: opacity .35s ease;
        font-size: 16px;
	}
  &_no-box-shadow {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

}

.jq-selectbox {
	width: 70px;
	&__trigger {
	display: none;
	}
	&__dropdown {
		box-shadow: 0 16px 30px rgba(1,22,41,.05);
		border: none;
		background: #fff;
		padding-left: 10px;
		padding-right: 10px;
		top: 38px!important;
		padding-bottom: 8px;
		border-radius: 0 0 12px 12px;
	}
	&__select {
		border: none;
		background: none;
		color: #020202;
		text-align: center;
		padding: 10px 5px;
		border-radius: 12px 12px 0 0;
		font-family: 'Proxima Nova', sans-serif;
		font-size: 16px;
		font-weight: 600;
		height: 38px;
		&:hover {
			background: none;
			color: #45b917;
		}
		&-text {
			width: auto!important;
		}
	}
	&.focused {
		.jq-selectbox__select {
			background: none;
		}
	}
	&.opened {
		.jq-selectbox__select {
			background: #fff;
			box-shadow: 0 16px 30px rgba(1,22,41,.05);
		}
	}
	li {
		text-align: center;
		border-top: 1px solid #f1f1f1;
		font-family: 'Proxima Nova', sans-serif;
		font-size: 16px;
		font-weight: 600;
		padding: 11px 5px;
		line-height: 9px;
        min-height: auto;
		&.selected, &.sel {
			display: none;
		}
		&:hover {
			background: none;
			color: #45b917;
		}
	}
}
.box-lang {
  margin-right: -18px;
}

.hover {
	color: red;
}

.dropdown-account {
  list-style: none;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 30px;
  border: 1px solid #40b711;
  background: #fff;
  padding: 62px 23px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
  box-shadow: 0 16px 30px rgba(75,79,74,.07);
  &__link {
    color: #000;
    font-weight: 900;
    &:hover {
      color: #40b711;
    }
  }
  &__item {
    border-bottom: 1px solid #f1f1f1;
    padding: 23px 0;
  }
  &.show-dropdown {
    opacity: 1;
    visibility: visible;
  }
}
