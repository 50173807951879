@media screen and (max-width: 1600px) {
  .program-works {
    margin-left: 0;
    left: 0;
  }
}

@media screen and (max-width: 1500px) {
  .request {
    margin-right: 0;
    left: auto;
  }
  .scroll-top {
    top: -35px;
    right: 5px;
  }
}

@media screen and (max-width: 1300px) {
  .program-works {
    &__img {
      padding-left: 125px;
      &-wrap {
        max-width: 100%;
      }
    }
    &__item {
      &_w1 {
        width: 40%;
      }
      &_w2 {
        width: 60%;
      }
    }
  }

}

@media screen and (max-width: 1100px) {
  .show-thanks-block {
    min-height: 876px;
  }
  .thanks-block__img {
    max-width: 511px;
    margin-right: -100px;
  }
  .thanks-block__text {
    width: calc(100% - 500px);
  }
  .footer__top {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .footer__item_btn {
    order: 1;
    width: 100%;
    .btn-wrap_row {
      justify-content: center;
    }
  }
  .utility-list__item-wrap {
    padding-left: 59px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .program-works {
    &__img {
      &_1 {
        img {
          top: -87px;
        }
        .program-works__elem {
          right: 14%;
          top: 26px;
        }
      }
      &_2 .program-works__elem {
        top: 26%;
      }
      &_3 .program-works__elem {
        top: 49.5%;
        right: 5%;
      }
      &_4 .program-works__elem {
        left: 5%;
        bottom: 2%;
      }
      &_5 .program-works__elem {
        left: 24%;
        bottom: 2%;
      }
    }
  }
}

@media screen and (min-width: 1025px){
  .utility-list__item:hover {
    padding-top: 0;
    &:after {
      opacity: 1;
    }
    .utility-list{
      &__number {
        font-size: 656px;
        left: 50%;
        top: 50%;
        transform: translate(-56%, -50%);
        opacity: .55;
      }
      &__info {
        color: #010101;
        font-size: 20px;
        padding-bottom: 0;
        margin-left: -13px;
      }
      &__content {
        padding-left: 13px;
        margin-left: -13px;
      }
      &__item-wrap {
        padding: 0 76px 0 0;
      }
    }
  }
  .rates-list__link:hover {
    &:after {
      z-index: 1;
      opacity: 1;
    }
    &:before {
      opacity: 1;
      box-shadow: 0 14px 60px rgba(1,28,53,.1);
    }
    .rates-list {
      &__img {
        background: #fff;
        color: #6abfdb;
        font-size: 120px;
        transform: translate(-50%, -65%);
        &:after {
          opacity: 1;
        }
      }
      &__title {
        font-size: 37px;
      }
      &__value {
        font-size: 53px;
        margin-bottom: 18px;
        &-wrap {
          bottom: 1px;
        }
      }
      &__text {
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .program-works {
    border-radius: 0;
    padding: 75px 0 37px;
    &-wrap {
      flex-direction: column;
      margin-left: 0;
    }
    &__img {
      padding-left: 0;
      &_1 {
        .program-works__img-wrap {
          width: 100%;
        }
        .program-works__elem {
          right: 130px;
          top: 108px;
        }
        img {
          right: -64px;
        }
      }
      &-wrap {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__item {
      width: 100%;
      &_w1 {
        margin-bottom: 30px;
      }
    }
  }
  .utility {
    padding: 100px 0 80px;
    &-list {
      margin: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding-top: 10px;
    &__wrap {
      flex-wrap: wrap;
    }
    &__item {
      padding-bottom: 20px;
      &_btn {
        width: 100%;
        order: 1;
        .btn-wrap {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
  .logo {
    margin-top: -11px;
    &__link {
      padding: 20px 20px 12px;
    }
  }
  .visual {
    padding-top: 230px;
    &__info {
      margin-right: 10px;
    }
    &__img {
      margin-right: -362px;
    }
  }
  .title {
    font-size: 50px;
    &__elem {
      display: inline;
    }
  }
  .request {
    padding-top: 90px;
    &__img {
      margin-top: 140px;
    }
  }
  .rates-list {
    justify-content: center;
    padding-top: 130px;
    &__item {
      width: 50%;
      margin-bottom: 60px;
    }
  }
  .rates {
    padding: 100px 0 130px;
  }
  .utility-list {
    padding-top: 30px;
    &__number {
      font-size: 245px;
    }
    &__info {
      padding-bottom: 0;
    }
    &__item {
      width: 50%;
      min-height: 210px;
      padding-top: 0;
    }
  }
  .header__item_btn .btn-wrap_row {
    margin-left: -10px;
  }
  .text-3 {
    padding-right: 0;
  }
  .title_max-width {
    font-size: 42px;
  }
  .scroll-top {
    top: auto;
    right: 10px;
    bottom: -23px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .program-works {
    &__img {
      &-wrap {
        width: 300px;
      }
      &_1 {
        .program-works__elem {
          right: 81px;
          top: 62px;
        }
        img {
          width: 991px;
          height: auto;
          right: -45px;
        }
      }
      &_3 .program-works__elem {
        top: 50.5%;
        right: 5%;
      }
      &_4 .program-works__elem {
        left: 5%;
        bottom: 3%;
      }
      &_5 .program-works__elem {
        left: 25%;
        bottom: 3%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .btn {
    min-width: 230px;
    padding-top: 15px;
    padding-bottom: 15px;
    &_snbmit {
      margin-right: auto;
      margin-left: auto;
    }
  }
  .visual {
    padding-top: 195px;
    &-wrap {
      flex-direction: column;
    }
    &__img {
      margin-right: auto;
      margin-left: auto;
      max-width: 500px;
      img {
        margin-left: 17%;
      }
    }
    &__info {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .btn-download {
    &-wrap {
      justify-content: center;
      flex-wrap: wrap;
    }
    &__item {
      max-width: 250px;
      width: auto;
    }
  }
  .title {
    font-size: 32px;
    text-align: center;
    &_fs1 {
      font-size: 33px;
    }
    &_pl {
      padding-left: 0;
      padding-bottom: 15px;
    }
    &_max-width, &_max-width-2 {
      max-width: none;
    }
  }
  .pre-title {
    font-size: 33px;
    text-align: center;
  }
  .program-works {
    &__img {
      &-wrap {
        width: 210px;
      }
      &_1 {
        .program-works__elem {
          right: 57px;
          top: 55px;
        }
        img {
          width: 740px;
          height: 400px;
          right: -45px;
        }
      }
      &_2 .program-works__elem {
        top: 27%;
      }
      &_3 .program-works__elem {
        top: 51.5%;
        right: 9%;
      }
      &_4 .program-works__elem {
        left: 8%;
        bottom: 5%;
      }
      &_5 .program-works__elem {
        left: 28%;
        bottom: 4%;
      }
    }
    &__elem {
      width: 60px;
      height: 60px;
    }
  }
  .step-list {
    padding-top: 0;
    &__item {
      padding: 0;
      margin-top: 0;
      &-content {
        border-radius: 20px;
        padding: 7px 15px 15px;
      }
    }
    &__title {
      font-size: 22px;
    }
  }
  .utility-list {
    margin-left: -5px;
    margin-right: -5px;
    &__number {
      font-size: 80px;
    }
    &__item {
      padding: 0 5px;
      min-height: 100px;
      height: auto;
      opacity: 1;
    }
    &__item-wrap {
      padding: 0 0 0 20px;
      align-items: flex-start;
    }
    &__info {
      font-size: 14px;
    }
  }
  .utility {
    padding: 30px 0;
  }
  .btn-wrap_row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .text-2 {
    text-align: center;
  }
  .request {
    border-radius: 0;
    padding-bottom: 50px;
    padding-top: 50px;
    &__form {
      margin-right: 0;
      width: 100%;
    }
    &__img {
      margin-top: 0;
      display: none;
    }
  }
  .box-field {
    &__submit {
      text-align: center;
    }
    &__input {
      input {
        padding-left: 20px;
        padding-right: 20px;
        height: 46px;
      }
    }
  }
  .text-3 {
    font-size: 18px;
  }
  .request-wrap {
    flex-wrap: wrap;
  }
  .thanks-block__text {
    width: 100%;
  }
  .check-mark {
    margin-left: auto;
    margin-right: auto;
  }
  .text-4 {
    text-align: center;
  }
  .show-thanks-block .thanks-block__img {
    display: none;
  }
  .rates-list__item {
    width: 100%;
  }
  .rates-list__link {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .footer__top {
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .contacts-list__item {
    text-align: center;
    padding: 10px 15px;
    width: 100%;
    line-height: 1.4;
  }
  .social-list__link span {
    font-size: 115px;
  }
  .social-list__item {
    margin: -25px;
  }
  .text {
    padding-right: 0;
  }
  .scroll-top {
    width: 45px;
    height: 45px;
    bottom: -12px;
  }
  .rates-list {
    &__img {
      font-size: 80px;
    }
    &__title {
      font-size: 25px;
    }
    &__value {
      font-size: 32px;
    }
  }
  .rates {
    padding-bottom: 50px;
    padding-top: 43px;
  }
  .logo__link {
    padding-top: 11px;
  }
  .box-lang {
    padding-top: 0;
  }
  .header__item-btn {
    margin-right: 10px;
  }
  .btn_bg:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 570px) {
  .header__item-btn-content-mob {
    width: 100%;
    text-align: center;
    display: block;
  }
  .header__item-btn {
    margin-right: 0;
  }
  .dropdown-account {
    padding-top: 46px;
    &__item {
      padding: 17px 0;
    }
  }
}