/* checkbox */
$checkbox-bg: #F6F6F6;
$checkbox-border-color: #C3C3C3;
$checkbox-border-width: 1px;
$checkbox-border-radius: 3px;
$checkbox-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$checkbox-checked-bg: #666;
$checkbox-checked-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$checkbox-disabled-opacity: 0.5;

/* radio */
$radio-bg: #F6F6F6;
$radio-border-color: #C3C3C3;
$radio-border-width: 1px;
$radio-border-radius: 50%;
$radio-shadow: 0 0 0 0px rgba(0,0,0,0);

$radio-checked-bg: #666;
$radio-checked-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$radio-disabled-opacity: 0.5;

/* select */
$select-bg: #F5F5F5;
$select-border-color: #C3C3C3;
$select-border-width: 1px;
$select-border-radius: 3px;
$select-shadow: 0 0 2px 0px rgba(0,0,0,0);
$select-height: 40px;
$select-padding: 10px 40px 0 15px;
$select-font-size:14px;
$select-line-height:18px;
$select-color:#000;
$select-font:Arial, sans-serif;

$select-opened-border-color: #000;

$trigger-arrow-bg:none;
$trigger-border-width:5px;
$trigger-arrow-color:#000;

$dropdown-bg:#FFF;
$dropdown-border-width:1px;
$dropdown-border-color:#000;
$dropdown-border-radius:4px;
$dropdown-shadow: 0 0 2px 0px rgba(0,0,0,0.1);
$dropdown-item-padding: 5px 10px 5px 10px;

$dropdown-item-color:$select-color;
$dropdown-item-font-size:$select-font-size;
$dropdown-item-line-height:$select-line-height;
$dropdown-item-font:$select-font;

$dropdown-item-active-color:#FFF;
$dropdown-item-active-bg:#000;

$dropdown-item-disbled-color:#ccc;
$dropdown-item-disbled-bg:none;


.jq-checkbox {
	top: -1px;
	width: 13px;
	height: 13px;
	border: $checkbox-border-width solid $checkbox-border-color;
	border-radius: $checkbox-border-radius;
	-moz-border-radius: $checkbox-border-radius;
	-webkit-border-radius: $checkbox-border-radius;
	background: $checkbox-bg;
	box-shadow: $checkbox-shadow;
	-moz-box-shadow: $checkbox-shadow;
	-webkit-box-shadow: $checkbox-shadow;
	vertical-align: middle;
	cursor: pointer;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 9px;
	height: 9px;
	margin: 2px 0 0 2px;
	background: $checkbox-checked-bg;
	box-shadow: $checkbox-checked-shadow;
	-moz-box-shadow: $checkbox-checked-shadow;
	-webkit-box-shadow: $checkbox-checked-shadow;
}
.jq-checkbox.focused {
	
}
.jq-checkbox.disabled {
	opacity: $checkbox-disabled-opacity;	
}


.jq-radio {
	width: 12px;
	height: 12px;
	border: $radio-border-width solid $radio-border-color;
	border-radius: $radio-border-radius;
	-moz-border-radius: $radio-border-radius;
	-webkit-border-radius: $radio-border-radius;
	background: $radio-bg;
	box-shadow: $radio-shadow;
	-moz-box-shadow: $radio-shadow;
	-webkit-box-shadow: $radio-shadow;
	vertical-align: middle;
	cursor: pointer;
}
.jq-radio.checked .jq-radio__div {
	width: 6px;
	height: 6px;
	margin: 3px 0 0 3px;
	background: $radio-checked-bg;
}
.jq-radio.focused {
	
}
.jq-radio.disabled {
	opacity: $radio-disabled-opacity;	
}


.jq-file {
	width: 270px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
	border-radius: 4px;
}
.jq-file input {
	cursor: pointer;
	height: auto;
	line-height: 1em;
}
.jq-file__name {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 32px;
	padding: 0 80px 0 10px;
	color: #333;
	font: 14px/30px Arial, sans-serif;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1;
	background: #FFF;
}
.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: #F1F1F1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAIAAABi9+OQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi+v//PxM2zMDAQEtxgAADAF2uTe9L4getAAAAAElFTkSuQmCC) repeat-x;
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
	color: #333;
	text-shadow: 1px 1px #FFF;
	font: 14px/30px Arial, sans-serif;
}
.jq-file:hover .jq-file__browse {
	background-color: #E6E6E6;
	background-position: 0 -10px;
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.disabled .jq-file__name {
	color: #888;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}


.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
	width:100%;
}
.jq-selectbox__select {
	height: $select-height;
	padding: $select-padding;
	border: $select-border-width solid $select-border-color;
	border-radius: $select-border-radius;
	background: $select-bg;
	box-shadow: $select-shadow;
	font-size: $select-font-size;
	line-height: $select-line-height;
	color: $select-color;
	font-family: $select-font;
}
.jq-selectbox__select:hover {
	background: $select-bg;
}
.jq-selectbox__select:active {
	background: $select-bg;
}
.jq-selectbox.focused .jq-selectbox__select {
	background: $select-bg;
}
.opened .jq-selectbox__select{
	border-color: $select-opened-border-color;
}
.jq-selectbox.disabled .jq-selectbox__select {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-selectbox__select-text {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 34px;
	height: 100%;
}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 14px;
	right: 12px;
	width: 0;
	height: 0;
	overflow: hidden;
	border-top: $trigger-border-width solid $trigger-arrow-color;
	border-right: $trigger-border-width solid transparent;
	border-left: $trigger-border-width solid transparent;
	background: $trigger-arrow-bg;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
	
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
	
}
.jq-selectbox__dropdown {
	top: $select-height;
	width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: $dropdown-border-width solid $dropdown-border-color;
	border-radius: $dropdown-border-radius;
	background: $dropdown-bg;
	box-shadow: $dropdown-shadow;
	-moz-box-shadow: $dropdown-shadow;
	-webkit-box-shadow: $dropdown-shadow;
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	outline: none;
	border: 1px solid #CCC;
	border-radius: 3px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	min-height: 18px;
	padding:$dropdown-item-padding;
	color: $dropdown-item-color;
	font-size: $dropdown-item-font-size;
	line-height: $dropdown-item-line-height;
	font-family: $dropdown-item-font;
}
.jq-selectbox li.selected {
	background-color: $dropdown-item-active-bg;
	color:$dropdown-item-active-color;
}
.jq-selectbox li:hover {
	background-color: $dropdown-item-active-bg;
	color: $dropdown-item-active-color;
}
.jq-selectbox li.disabled {
	color: $dropdown-item-disbled-color;
	background: $dropdown-item-disbled-bg;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}


.jq-select-multiple {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
	background: #FFF;
	color: #333;
	font: 14px/18px Arial, sans-serif;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}




