
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
.footer{
	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;
	box-shadow: 0 0 120px rgba(1,28,53,.1);
	background: #fff;
	padding: 33px 0;
	position: relative;
	&__top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: -5px -5px 9px;
	}
	&__logo {
		padding: 0;
		&-link {
			width: 220px;
			height: 46px;
			display: block;
			color: #8d8d8d;
			font-size: 46px;
			img {
				width: 100%;
			}
		}
	}
	&__item {
		padding: 5px;
	}
}

.social-list {
	list-style: none;
	display: flex;
	overflow: hidden;
	&__item {
		margin: -31px;
	}
	&__link {
		width: 66px;
		height: 66px;
		overflow: hidden;
		position: relative;
		span {
			font-size: 140px;
		}
		&:hover {
			opacity: .7;
			z-index: 1;
		}
	}
}

.contacts-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: -20px;
	&__item {
		padding: 20px;
		color: #555;
		font-size: 15px;
		font-family: "Solomon Sans", sans-serif;
		a {
			color: #555;
		}
	}
	&__title {
		font-weight: bold;
		font-family: "Proxima Nova", sans-serif;
	}
}

.scroll-top {
	position: absolute;
	right: 175px;
	top: 0;
	background: #00b71f;
	box-shadow: 0 12px 30px rgba(1,28,53,.15);
	border-radius: 50%;
	transform: translateY(-50%);
	width: 68px;
	height: 68px;
	&:after {
		content: '';
		display: block;
		width: 17px;
		height: 25px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background: url("../img/arrow.png") no-repeat;
	}
	&:hover {
		opacity: .8;
	}

}