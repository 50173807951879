
//main styles

.main-wrapper{
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
	background: url("../img/visual-bg.png") no-repeat;
	background-size: contain;
}
.wrapper{
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 20px;
	margin: 0 auto;
	position: relative;
}

/* titles */
p{	
	@include font($base-font-size,$base-line-height,$text-color);
	padding: 15px 0 15px 0;
	font-weight: 400;	
}
h1{	
	@include font($font-size-h1,$line-height-h1,$title-color);
	font-family: $title-font-family;
	padding: 15px 0 15px 0;
	font-weight: 400;	
}
h2{
	@include font($font-size-h2,$line-height-h2,$title-color);
	font-family: $title-font-family;
	padding: 15px 0 15px 0;
	font-weight: 400;	
}
h3{
	@include font($font-size-h3,$line-height-h3,$title-color);
	font-family: $title-font-family;
	padding: 15px 0 15px 0;
	font-weight: 400;
}

img {
	display: block;
	max-width: 100%;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{background:url(../img/loader.gif) no-repeat left top;width:40px;height:40px;position:fixed;left:50%;top:50%;margin-left:-20px;margin-left:-20px;display:none;}
.loaded .icon-load{display:block;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content{	
	min-width: $min-width;
	text-align:left;
    width: 100%;
	order: 2;
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;

}

.text-3 {
	font-size: 22px;
	color: #353535;
	line-height: 1.3;
	padding-bottom: 47px;
    padding-right: 50px;
}

.request {
	position: relative;
	background-color: #c5ecf9;
	left: -50%;
	margin-right: -740px;
	border-radius: 0 600px 600px 0;
	overflow: hidden;
	padding: 190px 0 0;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
	.wrapper{
		margin-right: 0;
		max-width: 1310px;
	}
	&-wrap {
		display: flex;
		position: relative;
		z-index: 1;
	}
	&__form {
		width: 475px;
		max-width: 100%;
		margin-right: 9%;
	}
	&__img {
		margin-top: -7px;
	}

}

.box-field {
	&__input {
		input {
			background: #fff;
			width: 100%;
			border-radius: 30px;
			padding: 5px 37px;
			height: 65px;
			border: none;
			color: #767676;
			font-family: 'Solomon Sans';
			font-weight: 600;
			font-size: 16px;
			&::placeholder {
				color: #767676;
			}
		}
	}
	&_mb {
		margin-bottom: 15px;
	}
	&-wrap {
		margin-bottom: 51px;
	}
}


.btn-download {
	display: inline-block;
	border-radius: 8px;
	background: #000;
	padding: 3px 17px 12px;
    min-width: 238px;
	&-wrap {
		display: flex;
		align-items: center;
		margin: -12px;
	}
	&__item {
		padding: 12px;
	}
	&__img {
		display: block;
		margin-top: -1px;
		&_1 {
			width: 180px;
			height: 42px;
		}
		&_2 {
			width: 191px;
			height: 44px;
		}
	}
	&__text {
		display: inline-block;
		color: #fff;
		font-size: 12px;
		&_1 {
			padding-left: 48px;
		}
		&_2 {
			padding-left: 54px;
		}
	}
  &:hover {
    opacity: .8;
  }
}

.visual {
	padding-top: 307px;
	&-wrap {
		display: flex;
	}
	&__info {
		width: 515px;
		max-width: 100%;
		margin-right: 188px;
	}
	&__img {
		margin-right: -285px;
		padding-top: 10px;
	}
}

.title {
	font-size: 54px;
	line-height: 1.2;
	font-weight: 900;
	color: #333;
	padding: 0 0 19px;
	&_fs1 {
		font-size: 53px;
	}
	&_bb {
		border-bottom: 1px solid #40b711;
	}
	&_less-pb {
		padding-bottom: 9px;
	}
	&_pl {
      padding-left: 107px;
      padding-bottom: 38px;
	}
    &_max-width {
      max-width: 436px;
    }
    &_max-width-2 {
      max-width: 550px;
    }
}

.pre-title {
	font-size: 37px;
	font-weight: 300;
	color: #353535;
	line-height: 1.2;
	padding-top: 17px;
}

.text {
	color: #050505;
	font-weight: 300;
	line-height: 1.5;
	padding-bottom: 41px;
	padding-top: 6px;
    padding-right: 30px;
}

.slick-track {
  transform: none!important;
}

.program-works {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #c5ecf9;
  left: 50%;
  padding: 152px 0 123px;
  margin-left: -790px;
  border-radius: 600px 0 0 600px;
  z-index: 1;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
	.wrapper {
		margin-left: 0;
		max-width: 1445px;
	}
  &-wrap {
		display: flex;
		position: relative;
	  align-items: center;
	  margin-left: -20px;
  }
  &__item {
	  z-index: 1;
	  &_w1 {
		  width: 52%;

	  }
	  &_w2 {
		 width: 48%;

	  }
  }
	&__img {
		padding-left: 210px;
		&-wrap {
			width: 401px;
			position: relative;
		}
		&_1 {
			&-wrap {
				padding-left: 0;
				width: 100%;
			}
			img {
				position: absolute;
                right: -153px;
				max-width: none;
				width: 1350px;
				height: 733px;
			}
          .program-works__elem {
            right: 10%;
            top: 109px;
          }
		}
      &_2 {
        .program-works__elem {
          left: 2%;
          top: 29%;
        }
      }
      &_3 {
        .program-works__elem {
          top: 52.5%;
          right: 10%;
        }
      }
      &_4 {
        .program-works__elem {
          left: 9%;
          bottom: 5%;
        }
      }
      &_5 {
        .program-works__elem {
          left: 29%;
          bottom: 5%;
        }
      }
	}
	&__elem {
		display: block;
		position: absolute;
		border-radius: 50%;
		border: 3px solid #00b900;
		width: 104px;
		height: 104px;
		z-index: 2;
		&:after, &:before {
			content: '';
			position: absolute;
			border-color: #00b900;
			border-style: solid;
			border-radius: 50%;
			opacity: .3;
		}
		&:before {
			border-width: 2px;
			top: -20px;
			bottom: -20px;
			left: -20px;
			right: -20px;
		}
		&:after {
			border-width: 1px;
            top: -40px;
            bottom: -40px;
            left: -40px;
            right: -40px;

		}
	}
}

.slider-for .slick-list{
	overflow: visible!important;

}

.step-list {
  list-style: none;
	padding-top: 50px;
	padding-bottom: 20px;
	.slick-list, .slick-track {
		height: auto!important;
	}
	.slick-list {
		overflow: visible!important;
	}
  &__item {
    outline: none;
    cursor: pointer;
	  padding: 30px;
	  margin-top: -50px;
	  width: 100%!important;
    &.slick-current {
      .step-list {
        &__item-content {
          background: #f5f6f8;
          box-shadow: 0 12px 30px rgba(1, 22, 41, .1);
        }
        &__title, &__description{
          color: #00b71f;
        }
      }
    }
    &-content {
      border-radius: 40px;
		padding: 7px 80px 28px;
    }
  }
  &__title {
    font: 900 30px/1.2 'Proxima Nova', sans-serif;
    color: #333;
	  letter-spacing: .6px;
	  padding-bottom: 5px;
  }
  &__description {
    font-size: 14px;
    font-weight: 100;
	  line-height: 1.3;
    text-shadow: 0 0;
  }
}

.elipse {
	position: absolute;
	z-index: 1;
	max-width: 100%;
	max-height: 100%;
	width: 1962px;
	height: 1962px;
	background: url(../img/elipse.png) 100% 100% no-repeat;
	background-size: contain;
	&_1 {
		bottom: -61%;
		left: 15%;
	}
	&_2 {
		top: -51%;
		left: -23%;
		opacity: .29;
	}
	&_3 {
		opacity: .29;
		top: -43%;
		left: -2%;
	}
	&_4 {
		right: -2%;
		bottom: -44%;
	}
}

.imprint_bg {
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		background: url("../img/imprint.png") no-repeat;
		width: 52.8%;
		height: 109%;
		max-width: 997px;
		max-height: 1320px;
		z-index: -1;
		background-size: contain;
	}
  &_1:after {
    left: -13%;
    bottom: -58%;
  }
	&_2:after {
		right: -13%;
		bottom: -30%;
	}
	&_3:after {
		transform: rotate(37deg);
		left: -16%;
		bottom: -64%;
	}
}

.utility {
	padding: 155px 93px 95px 100px;
}

.utility-list {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	position: relative;
    padding-top: 60px;
	margin: 0 -95px 0 -6px;
	&__item {
		width: 33.3%;
		position: relative;
        min-height: 307px;
        margin-bottom: 23px;
		z-index: 2;
        padding-top: 23px;
		height: 100px;
        transition: opacity .5s ease;
        opacity: 0;
        &:nth-child(2) {
          transition-delay: .5s;
        }
        &:nth-child(3) {
          transition-delay: 1s;
        }
        &:nth-child(4) {
          transition-delay: 1.5s;
        }
        &:nth-child(5) {
          transition-delay: 2s;
        }
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
            left: -90px;
			right: 0;
			bottom: 0;
			opacity: 0;
			z-index: 0;
			border-radius: 12px;
			background: #fff;
			box-shadow: 0 12px 30px rgba(1,28,53,.1);
		}
	}
  &__content {
    height: 100%;
    overflow: hidden;
  }
	&__item-wrap {
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;
		padding: 0 50px 0 79px;
	}
	&__number {
		display: block;
		font-family: 'Solomon Sans', sans-serif;
		font-weight: 900;
		font-size: 273px;
		color: #e8f6fa;
		position: absolute;
		left: 0;
		top: 0;
		transition: all .35s linear;
		z-index: 1;
	}
	&__info {
		z-index: 1;
		transition: padding .35s ease;
		color: #272727;
		line-height: 1.3;
        padding: 0 0 27px 0;
	}
}

.text-2 {
	padding: 0 0 40px;
	color: #353535;
	font-size: 14px;
}

.rates {
	padding: 175px 0 230px;
}

.rates-list {
	list-style: none;
	margin: -53px;
	display: flex;
	flex-wrap: wrap;
	padding: 205px 43px 0;
	&__item {
		padding: 10px;
		width: 33.3%;
		min-height:315px;
	}
	&__link {
		display: block;
		position: relative;
		background: #e7f8fd;
		border-radius: 12px;
		&:after {
			border-radius: 12px;
			content: '';
			display: block;
			position: absolute;
			left: -43px;
			right: -43px;
			bottom: -35px;
			top: -22px;
			opacity: 0;
			z-index: -1;
			background: #fff;
			box-shadow: 0 14px 60px rgba(1,28,53,.1);
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 209px;
			height: 209px;
			background: #fff;
			border-radius: 50%;
			left: 50%;
			top: -17px;
			opacity: 0;
			transform: translate(-50%,-50%);
		}
	}
	&__title {
		font-family: 'Proxima Nova', sans-serif;
		font-weight: bold;
		font-size: 30px;
		text-align: center;
        transition: font-size .15s ease;
		line-height: 1;
		padding-bottom: 10px;
	}
	&__img {
		margin-left: auto;
		margin-right: auto;
		font-size: 94px;
		color: #a4c5d0;
		transform: translate(-50%,-50%);
		position: absolute;
		background: #e7f8fd;
		top: 0;
		left: 50%;
		width: 168px;
		height: 168px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
        transition: font-size .15s ease, color .15s ease;
		z-index: 2;
		&:after {
			border-radius: 50%;
			content: '';
			position: absolute;
			background: #fff;
			opacity: 0;
			left: -21px;
			right: -21px;
			bottom: 0;
			top: 21px;
		}

	}
	&__value {
		font-weight: 900;
		font-size: 43px;
		color: #40b711;
        transition: font-size .15s ease;
		margin-bottom: 13px;
		&-wrap {
			text-align: center;
			position: absolute;
			bottom: 32px;
			left: 15px;
			right: 15px;
		}
	}
	&__text {
		font-weight: 100;
		color: #517e8d;
        transition: font-size .15s ease;
	}
	&__icon {
		position: relative;
		z-index: 3;
		display: inline-block;
	}
	&__info {
		position: relative;
		z-index: 2;
		padding: 46px 15px 138px;
	}
	&__pre-title {
		font-size: 14px;
		text-align: center;
		display: block;
		color: #000;
		line-height: 1.3;
	}
}

.new-line {
	display: block;
}

.hide-tab{
	position: absolute;
	left: 0;top: 0;
	height:0; width:0;
	overflow:hidden;
	z-index: -1;
	opacity: 0;
}

.nav-tab-list__item.active {
	.btn {
		color: #fff;
		background: #40b711;
		box-shadow: 0 12px 30px rgba(1, 22, 41, 0.1);
	}
}

.show-thanks-block {
	.hidden-form {
		display: none;
	}
	.thanks-block__text, .thanks-block__img {
		display: block;
	}
}
.thanks-block {
	&__text {
		width: calc(100% - 685px);
		display: none;
		padding-top: 192px;
	}
	&__img {
		max-width: 685px;
		display: none;
		max-height: 881px;
	}
}

.text-4 {
	font-size: 36px;
	color: #333;
	line-height: 1.2;
	padding-top: 0;
}

.check-mark {
	display: block;
	height: 114px;
	width: 114px;
	border-radius: 50%;
	margin-bottom: 26px;
	background: url("../img/check-mark.png") no-repeat;
}



.tab-cont.active {
  .utility-list__item {
    opacity: 1;
  }
}



/*PRELOAD*/
.preload{
    position: absolute;
    top: 0;
    background: white;
    z-index: 1111;
    padding-top: 35px;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: scroll;
    display: none;
    .visual__img{
        width: 100%;
        overflow: hidden;
    }
    .visual__img img{
         position: relative;
        bottom: 0px;
    }
    .btn-download-wrap{
        margin-left: 0;
        margin-right: 0;
    }
    .visual-wrap{
        justify-content: space-between;
        height: 100%;
        display: block;
        padding-bottom: 000px;
        position: relative;
        min-height: 610px;
    }
    .btn_bg{
        display: block;
    }
    .btn-download__item{
        padding: 0;
        width: calc(50% - 10px);
        display: flex;
        justify-content: center;
    }
    .btn-download-wrap{
        justify-content: space-between;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
.visual__img{
        max-width: 340px;
        position: absolute;
        bottom: 0;
    right: 0;
    }
  .btn-download-wrap{
        flex-direction: column;
        
    }
    .btn-download__item{
        margin-bottom: 15px;
    }
}
.preload-body{
    overflow: hidden;
    .main-wrapper{
        overflow: hidden;
        min-height: 100%;
        height: 100%;
        width: 100%;
    }
    .content{
       
    }
    .btn-preload-js-src{
        margin-bottom: 15px;
    }
}
.btn-preload__next{
    color: black;
    height: 40px;
    width: 150px;
    text-align: center;
    padding-top: 13px;
    &:hover{
        color: #40b711;
    }
}

.btn-preload-js-src{
    display: none !important;
}
.android{
    .android-download{
        display: block !important;
    }
}
.iphone{
    .iphone-download{
        display: block !important;
    }
}
@media screen and (max-width: 767px){
    .preload.active{
        display: block;
    }
}
@media screen and (max-width: 500px){
    .preload .btn-download-wrap{
        flex-direction: column;
        
    }
    .preload .btn-download__item{
        margin-bottom: 15px;
    }
}
/*END PREDLOAD*/


